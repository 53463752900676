import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "filtering-is-the-mechanism-by-which-a-user-adds-or-removes-data-items-from-a-displayed-data-set-by-turning-on-and-off-certain-predefined-attributes"
    }}><em parentName="h3">{`Filtering`}</em>{` is the mechanism by which a user adds or removes data items from a displayed data set by turning on and off certain predefined attributes.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Selecting filters</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Triggering filters</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Filter interaction</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "selecting-filters"
    }}>{`Selecting filters`}</h2>
    <h3 {...{
      "id": "single-selection"
    }}>{`Single selection`}</h3>
    <p>{`A `}<em parentName="p">{`single-selection filter`}</em>{` is employed when the user can pick `}<strong parentName="p">{`only one attribute`}</strong>{` from a category to modify the data results. Under the hood, single selection behaves like a radio button. Ways in which a single selection filter can manifest include:`}</p>
    <ul>
      <li parentName="ul">{`Basic dropdown`}</li>
      <li parentName="ul">{`Inline dropdown`}</li>
      <li parentName="ul">{`Search`}</li>
      <li parentName="ul">{`Radio button set (either standalone or within a menu)`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACJUlEQVQoz5VSOYhaURT9i2ZGHVf4KOkkEEFIijQ26cYNnAyjgyjBwhgIaWwsAlo5KGqhjZWglSBYWFpYWCi4FBaCouICbqAi7tMkxsTc9wcz02QgFy7nP+5595537scwCLPZjJ3icDhgx+MRP+Vms8Fnsxn2XIjF4seDTqejUa1W4zabjfjXJZVKhYtEIpoPXEwqleIymQxTKBRIAOZwOB6IVquVRmjG1Gq1F9VqlZPJZN6m0+l3o9Ho9f39/av9fn8OFLZEImGfBgiFQgGfz794OrTb7WIYj8ejD0ajkQvAgOepJ5PJ7/l8/mO5XH5fLBYo3yMOKPn7gkgkQobDYRJZ0m63iV6v91DQaDQ0+nw+AUVRQihcj8fj42Aw+DmdTg/D4fBXLpe7lcvlArfb/dLpdFIul4tKJpMsGMBoNptMuMMEdTjdKBQK0djv9xkIQdkHWMzxaTQajRsovfB4PNx4PE74/X6yWCwiZXin08FRM/q5KAwGA42xWIxtMpm49XpdDsRvtVrtDtDbarW82Wz2jcViOQ8Gg0JoKvB6vYJoNMrZ7XYMqCN1jwpBEYG8SaVSTPCEw2KxTj4hxWgZZ3q9ngJLeIFAgA1NOaCQk0gkzuAeCZaQ0INcrVYE+Ek893uhIq5UKknsf2K9XqtB+g0s4Aq8MsG0W5h0WalUPhcKBXs+n/9UKpXs8P0V0loul+2wpC9gyUfYvmG73V4C/wryGlL5B6YvMUp3VhkqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "single selection filters",
            "title": "single selection filters",
            "src": "/static/ae4c48e02583d7e4e96476c6b1be77fb/fb070/filter-1.png",
            "srcSet": ["/static/ae4c48e02583d7e4e96476c6b1be77fb/d6747/filter-1.png 288w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/09548/filter-1.png 576w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/fb070/filter-1.png 1152w", "/static/ae4c48e02583d7e4e96476c6b1be77fb/c3e47/filter-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "multi-selection"
    }}>{`Multi-selection`}</h3>
    <p>{`A `}<em parentName="p">{`multi-selection filter`}</em>{` is employed when the user has the option to pick `}<strong parentName="p">{`more than one attribute`}</strong>{` from a category to modify the data results. Under the hood, multi-selections behave like `}<em parentName="p">{`checkboxes`}</em>{`. Ways in which a single selection filter can manifest include:`}</p>
    <ul>
      <li parentName="ul">{`Multi-select dropdown`}</li>
      <li parentName="ul">{`Inline multi-select dropdown`}</li>
      <li parentName="ul">{`Search with tags`}</li>
      <li parentName="ul">{`Checkbox set (either standalone or within a menu)`}</li>
    </ul>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.18478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB5UlEQVQoz61RO4gaURR9M+7oOCabxSKCy5Z+SkXQymJhd5Ow634KS4stBSutTJdeTBUUDcrayoq1CH4KsfcDfkEE/6IWwsoiL+dNfiSEQCAXDue8Offde98dotPpCAvGv8NkMpFcLif76/WaUEpJLBbjEokEF41GuXw+z2UyGfI99Ho9IWq1Wj5YrVZiMBiI2WwmDoeDeDweYjQaZbBCf4tsNkv+OdDw1GazvQ+FQq+DwaDe5/OdxONxbSAQ0MCWtFotJycKgiCzxWI5xBOPGex2+6Hb7VYy7XK5XjE/nU5/rlartFarjZvN5hyYtFqtp3q9/oH5jUaD/+Ul6PoCXV/6/X4NOqvC4bDG6/UeoZC8k1KplBoMBhQXn9vtNoV+xl5pr9f7yPzZbManUqmvxVhlXFCCDwAFA/ai6nQ6ArSS5YzH40dout1uGdH9fi/zarWKMH+32yk2m83PCZPJpLJYLAp4ygE6KyKRiIhJxeVyKe+k2+3eY5qH6XT6aT6fRwHGD2jkZj6Kcf1+n5DFYsGjG1+pVNTYiTgcDkWMryoUClK5XJYmk4kKF0Wn06n408+SJIkbjUYiq4NBePLfA1UvgDuM/Bb8jmns5RznK+AG+gzfbqEvgYtv/hvoH7lgF3ANnH0BUgg0OLFeZwEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "multi-selection filters",
            "title": "multi-selection filters",
            "src": "/static/b02bd2ecd58c44ce11dadeedd0112835/fb070/filter-2.png",
            "srcSet": ["/static/b02bd2ecd58c44ce11dadeedd0112835/d6747/filter-2.png 288w", "/static/b02bd2ecd58c44ce11dadeedd0112835/09548/filter-2.png 576w", "/static/b02bd2ecd58c44ce11dadeedd0112835/fb070/filter-2.png 1152w", "/static/b02bd2ecd58c44ce11dadeedd0112835/c3e47/filter-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "multiple-categories"
    }}>{`Multiple categories`}</h3>
    <p>{`A `}<em parentName="p">{`category`}</em>{` is a set of filter items within the same topic. For example, “size” is the category and `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`, and `}<inlineCode parentName="p">{`extra large`}</inlineCode>{` are the filter choices. Multiple filter categories may be applied to the same data set. For example, the user can filter by size as well as color and price range.`}</p>
    <p>{`Multiple category selection is most commonly manifested in either a vertical list on the left side of the page or within a horizontal drawer at the top of the data set. Multiple categories should never be put within a menu or dropdown.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.22282608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAC2klEQVQ4y43TX0hTURwH8N/9s3+i0/273m1azk1LZ+UeLCod2YsPYSJIgqJWG6tlUBCImEFGGWQaVNBDBRVRhNabA59aLxJOIQMfFDVdKWZs00kvEt6+d2wixcADH37nHM75nXPPOZeoUyLySxx3ZpqjixLHtP3mySMpaAj9O4ogCLSrom7+xikavmbzzT8ylPWfsxSN01rySdlC3TvGKRBT4HAyVquVl8eOjo5SKBSi2dnZtOTCGnM0dqMuq8ig5a2GDDKZtKzNoNfZDQaDTa/X5xiNRgfttsS+f2EfddTw7Y0Vyp7efr6zZ0BR19Ci8Xi8qhvd3apAIKAeGRlRzMzM8CsrK/zy8nJCOBzmFhYW/kN3PsQ0Fbcl0/E+yRia/6OMroYV8kKSJKmGh4eZdBsZHBxkJicnufHxcW5iYmIblVS1cpWtj9WkfMJiXCKBzWbz4xKOIOm+tbW1wMbGRhvchAHog/vgh3PggfMplJ9nzrCIJrtVyNbaCvYmDt/lclkRVJubm6eQVIrH47fAB13QkVQGZsjfiXJFc5YoWlyCaBFzRZHHfEp+Moszy8SqNgwsRryHKO+wP+k52i/hBerbqKo8jz1kJo1ZS1qzKGSmzmhqakqdqmOSDp5iwjNIRTfICzmhNIXIKzF4yGq6LCUu4+dbt7w7CgaDHJLkYtBpRHlwJZyEahn63FD1LypsHGJtTQFdact7XW3jhUTSaCzOJnfWkDzDh/AA3iCZ/JmvEGsQyxEPQ0UK1V97bWy7crfSRaTw+q8q5URrsQj5fD4GEwoxqBPxBGITeHfcqB/97YiXELdRRc3ZDOfR2j3V7mMqOVlobIzd2pJocXFRs+MMrfARPkEwqRfkxa4nyS+gKzVHvgwu3SPGVzPYyQEoS8aDaX+91dVf++fm5suWlpYc6+vrJbFYrBiP2R6JRMpRd6Aut4vRFqLRaB5YQEBfaqz8+J2oFyGW/gWQwjubpqIIaAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Multiple filter categories examples",
            "title": "Multiple filter categories examples",
            "src": "/static/aeb30ee6a2088dc356072b4aabf47f37/fb070/filter-3.png",
            "srcSet": ["/static/aeb30ee6a2088dc356072b4aabf47f37/d6747/filter-3.png 288w", "/static/aeb30ee6a2088dc356072b4aabf47f37/09548/filter-3.png 576w", "/static/aeb30ee6a2088dc356072b4aabf47f37/fb070/filter-3.png 1152w", "/static/aeb30ee6a2088dc356072b4aabf47f37/c3e47/filter-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "triggering-filters"
    }}>{`Triggering filters`}</h2>
    <h3 {...{
      "id": "batch-filter"
    }}>{`Batch filter`}</h3>
    <p>{`A `}<em parentName="p">{`batch filter`}</em>{` is employed when all filters are applied together at the end of the selection process. The data set only refreshes once. The trigger is most commonly an “Apply filter” button.`}</p>
    <p>{`The batch filter works best when the user is making several filtering selections across different categories that may take a longer time to mentally process. Batch filtering is also a good solution for slow data-return speeds. This can prevent the user from having to wait for the data to load after every selection.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.57065217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB7ElEQVQoz62TT4vTQBjGO9X1tqdW1E8hlO3SjabJsrVXYb/ChpXFoiie/C49uCg9llh78eChFSt4KKW9FNpCoX9tm39tdpNOMq/vzK6gZC+CAw/vzDN5f/MkTGKxP8Zms4n9t+H7vqjL5TIGAKRUKpFisUiazSbpdDqEe0EQkFardYs/NxqN3tTr9Yc4JePxmESAq9Uqfg1M4fzHer3+hokbjuMIodcwTbOB/ndc13APBoOByns8z4tHgPP5XJiLxeLItm0wDEMIkwNjDBAGeBhQSgGTioreHu/BGgUOh0NhzmYztd1u89MpwgNME2CaoNvtBr1eT6wx5RYH4P4+78EAUeBkMvkNPJxOp9Dv9xkmZDytZVlgWo6QZTvMsteh51OY/1wKoGHeAHRdN35dD/npHIbfhoVhCAG+Hts6AFdiqBAncOlcJQw9KwrUdV2Y5XJZrVargNpWKhVa+ahT/dNn+r5m0/OvHj2vX6Au/Q8NCsUvVpr3vKu5UWAmkxFmOp1+ksvlIJ/Pw6PHMhzspyAlP4XkyxDuvQXYfQ1wuwBw5xXAjuYd8J6d0zAKzGaz4i7JsvxAUZRTVVVPFEXVVFnSpKNj7f7ZREu+8LVkwdUShYsT1LPEmXGX9ySeb8iNl1uSpH/+IXYL8Nf6F3bfdJXMfGA/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Batch filter example",
            "title": "Batch filter example",
            "src": "/static/626cfe08ee74806c6fcbe31561d7017c/fb070/filter-4.png",
            "srcSet": ["/static/626cfe08ee74806c6fcbe31561d7017c/d6747/filter-4.png 288w", "/static/626cfe08ee74806c6fcbe31561d7017c/09548/filter-4.png 576w", "/static/626cfe08ee74806c6fcbe31561d7017c/fb070/filter-4.png 1152w", "/static/626cfe08ee74806c6fcbe31561d7017c/c3e47/filter-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "interactive-filter"
    }}>{`Interactive filter`}</h3>
    <p>{`An `}<em parentName="p">{`interactive filter`}</em>{` returns results after each individual selection is made. The trigger is the individual selection and the filter manipulates the data in real time. This is a good solution for when the user is only selecting from one category or the user is expected to only make one filter selection.`}</p>
    <h2 {...{
      "id": "filter-interaction"
    }}>{`Filter interaction`}</h2>
    <p>{`Each filter interaction should contain the following patterns and states:`}</p>
    <ul>
      <li parentName="ul">{`Default start state`}</li>
      <li parentName="ul">{`Unselected and selected state`}</li>
      <li parentName="ul">{`Filter applied state`}</li>
      <li parentName="ul">{`Clear filter(s) action`}</li>
    </ul>
    <h3 {...{
      "id": "starting-states"
    }}>{`Starting states`}</h3>
    <p>{`Filters within each category should start either as `}<em parentName="p">{`all unselected`}</em>{` or `}<em parentName="p">{`all selected`}</em>{`. When using multiple categories, the start state can vary from category to category. If the user wants only one or a few criteria to be excluded from the results, then all filters should be selected at the start. Vice-versa, if the user wants to see only results related to one particular criteria, then all filters should start as unselected.`}</p>
    <h3 {...{
      "id": "filter-applied"
    }}>{`Filter applied`}</h3>
    <p>{`If the filter(s) can be hidden in either a drawer, dropdown, or menu, then there should be an indicator visible on the closed filter state that informs the user that filters have been applied. At a minimum, the indicator should include the number of filters applied and have the option to clear filters without re-opening the filter container.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.91847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABlklEQVQoz52TzWrCQBDHd4vxA7xYe2gfpWBPgtc+hUfx6tGLeNM3iAcvAY9BH0ChTyDiV4xGE038Dgq9SDqzZGNDxGIHNjuT2f3lPztZQlzL5XLkvzYej6/BaDQi2WyWQ6P5fD7uOA4dDAZPkPMN2OjNp9OJuj7lHAbGR71eZ0BZloVmsxmbTCYCLAjjgLw3eDwcDsPtdjvivgtxlQj1rNPpPFRmIpEIc19RFH/9hUKB+aVSKQ5q41CO0O/3I79UoY/KY7PZLKZpWlSSJDwWzAmc45VcrVYZUBRF2mq16HK5pFAWnhFVVZXWarVbjXiFdQxmWdYVyK1cLgc2QXPYfDgcQgB+13U9Deo/IP4E2Nd6vX5zgdTX5WKxyPxKpUIajQbZ7XYEyvEWbjab58ViYZzPZ+d4PDqXywVnbTqdJjFvmiYNKLxlHLjdbhGoQOyAsm/bthGownkmAwrvGX7ZBSbn87mJCnGg7fd7C4AvmF+tVvShGwAbQ71eLwXHkDEMIw3wDHQ61e12hcBN+cvg63fz8Cv54h/EhVSjENJQ+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Hidden filters not applied",
            "title": "Hidden filters not applied",
            "src": "/static/cc9e37db91f2eeb07520d441f0bee16d/fb070/filter-5.png",
            "srcSet": ["/static/cc9e37db91f2eeb07520d441f0bee16d/d6747/filter-5.png 288w", "/static/cc9e37db91f2eeb07520d441f0bee16d/09548/filter-5.png 576w", "/static/cc9e37db91f2eeb07520d441f0bee16d/fb070/filter-5.png 1152w", "/static/cc9e37db91f2eeb07520d441f0bee16d/c3e47/filter-5.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.91847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABuUlEQVQoz51Tu2oCQRTdMbsag1UMaArrfIR2AXs/YkuxCWKhYCN2+gcWWlqKtoLbWVgJvo0PXN0VnygEESZnBnfFGMzjwN2dO/fOuefeYQThhGAwKPwX3W737HQ6HUGWZYP0PhQKOSilpNVqWRAzbTgcWiqVigUxnjufz/l+r9cjBg8nZp9cLseTCoWCVCwW7f1+X0KClRni3Or1uhVkYiwWcwQCAVe1WuX7KCwaKhmpCUVRftWe2+1+8Xg8b1jeGXtQedl/JBLh60Qi4YBax263k5rNpo0pOKm0NRoNO1Q+QKWNjaRcLhPkWEEmGTxmy+l0mhNmMhlSKpXIdDol7XabgIxgdiQajX4V6kLMhTxOpuv6mdBAMpm8as+4gMPhIGIkXvjebDYrh8PhMkiU2Wz2fCIkF7ccj8f5OpVKCfl8XlgulwKGbSbu9/vHWq2mYhR0vV7T4/FIN5vNaDAYOFlc0zRypfA7GITb7fYRBXo4SNHmB3xG+I5xOK8U3gKrzP6LxcI5mUw0KKXMGFarlQ7CJxZH6+RPLwAHRdywDyr9qqq+jsdj/2g08mFPunopPwHVb8bxCC78T3UWT1mxts6sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Hidden filters applied",
            "title": "Hidden filters applied",
            "src": "/static/408f7f7427d3688ce4a00c50da5f73ca/fb070/filter-6.png",
            "srcSet": ["/static/408f7f7427d3688ce4a00c50da5f73ca/d6747/filter-6.png 288w", "/static/408f7f7427d3688ce4a00c50da5f73ca/09548/filter-6.png 576w", "/static/408f7f7427d3688ce4a00c50da5f73ca/fb070/filter-6.png 1152w", "/static/408f7f7427d3688ce4a00c50da5f73ca/c3e47/filter-6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>
### Resetting filters
    <p>{`Each category should have a way to clear all applied filters at once without having to interact with each individual item. Clearing filters returns the filters to their original default starting state.`}</p>
    <p>{`If multiple categories have been applied to the same data set then there should be a way to dismiss all filters across all categories at once.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.38586956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABMElEQVQY03XQv0vDQBQH8EsTpMUfTUorBSvWpDHRqpHaISD4D2TSuSpEwdFJs1oRl4JVZzdBNxdH5/wzGfKTDGY5v3cEJ3vw4b0Ld+/yHiFsnVEeyIgKxKV1ckmXRPenQspFKRU9zxPIjJVlmQgSwz8cX3/yePHwLRy6r4u1g0mV7U1jQ1FV1UNaQ9H9NE2/4ARu4B7GcIdC5zCCU15ouanw2Go25tst2VhpSAvyHKlYewPJsqxu+RdHKEpZIeRXwOItjGEXOtAlSZIIvu/z9mzbrmuaNjQMs+04ThX3/1oOgkDBhVUUNGECTzAtvcEHvP83EjYH1rI4Y14yPMIzTOEFhrAGOonjWAcTNqMo6uGVraIo9DzPdez76KAXhmEfLOTs7DZYsAMDFnFuHVTknV88rOKp85JtBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Filter reset example",
            "title": "Filter reset example",
            "src": "/static/b75426a8be0d165c445392b646eb0002/fb070/filter-7.png",
            "srcSet": ["/static/b75426a8be0d165c445392b646eb0002/d6747/filter-7.png 288w", "/static/b75426a8be0d165c445392b646eb0002/09548/filter-7.png 576w", "/static/b75426a8be0d165c445392b646eb0002/fb070/filter-7.png 1152w", "/static/b75426a8be0d165c445392b646eb0002/c3e47/filter-7.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption"></Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      